import React, {useContext, useEffect, useState} from 'react';
import {TKeys} from "../assets/text";
import {contentFr} from "../assets/text/content.fr";
import {contentEn} from "../assets/text/content.en";
import {contentNl} from "../assets/text/content.nl";
import { createContext } from "react";
import {Tw} from "./Tw";
import {useParams} from "react-router-dom";
import {App} from "../components/App";

export enum AppLangs {
  fr = 'fr',
  en = 'en',
  nl = 'nl',
}

interface LangCtx {
  language: AppLangs,
  setLanguage: (language: AppLangs) => void;
}

export const LangContext = createContext<LangCtx>({
  language: AppLangs.en,
  setLanguage: console.log
});

interface Props extends Tw {
  children: any;
}

// Provider
export const LangRoot =  (p : Props) => {
  // @ts-ignore
  const browserLang = window.navigator?.userLanguage || window.navigator?.language;
  const [language, setLanguage] = useState<AppLangs>(getAppLang(browserLang))

  const { locale } = useParams();
  useEffect(() => {
    if(locale && Object.values(AppLangs).includes(locale as AppLangs)){
      setLanguage(locale as AppLangs)
    }
  }, [locale])


  const {children} = p;
  return (
      <LangContext.Provider
          value={{language, setLanguage}}>
        {children}
      </LangContext.Provider>
  );
}

// Consumer
export const useTranslation = () => {
  const {language, setLanguage} = useContext(LangContext);

  // to memoize if language did not change, as every screens will have them
  const t: (key?: TKeys) => string = React.useCallback(
    (key?: TKeys) => {
      if (!key) return '';
      const fallback = contentFr[key] || key;
      switch (language) {
        case AppLangs.fr:
          return contentFr[key] || fallback;
        case AppLangs.en:
          return contentEn[key] || fallback;
        case AppLangs.nl:
          return contentNl[key] || fallback;
        default:
          return fallback;
      }
    },
    [language, setLanguage],
  );
  const output = {t, setLanguage, language};
  return output as TranslationTypes;
};
export interface TranslationTypes {
  t: (key?: TKeys) => string;
  setLanguage: (ln: AppLangs) => void;
  language: string;
}

export const getAppLang = (sysLang: string = 'en') =>
  Object.values(AppLangs).find((ln) => sysLang.toLowerCase().startsWith(ln)) ||
  AppLangs.en;



