import React from 'react';

interface Props {
    children: any;
}
export const Container =  (p : Props) => {
    const {children} = p;
    // return (<div className="container mx-auto p-5 bg-bordeaux-100">
    return (<div className="container mx-auto p-5 bg-bordeaux-100">
        {children}
    </div>);
}
