export const contentFr = {
    short_title: 'Le Chéniat',
    title: 'Chambre d\'hôtes - Le Chéniat',
    short_desc: 'Chambre d\'hôtes en Brabant wallon spacieuse & chaleureuse',
    _figcaption: 'Classification officielle chambre d\'hôtes en Wallonie : 3 épis',
    figcaption: 'Reconnu par le Commissariat Général au Tourisme: Chambre d\'Hôtes classé 3 Épis',
    figcaption_bike: 'Label Bienvenue Vélo',
    section_openings: 'Accueil',
    hours_1: 'Ouvert toute l\'année',
    hours_2: 'Arrivée entre 17h00 et 21h00',
    hours_3: 'Départ au plus tard à 11h00',
    section_rooms: "Notre suite à deux chambres",
    section_rooms_short: "Chambres",
    subsection_A: "Chambre principale",
    subsection_A_desc: "Une chambre spacieuse de 30 m² avec un lit double et un lit simple optionnel",
    subsection_B: "Chambre annexe",
    subsection_B_desc: "Une chambre attenante de 9 m² avec un lit simple et un lit bébé ainsi qu'une table à langer",
    section_options: "Les options",
    section_options_short: "Options",
    subsection_C: "Salle de bain privative",
    subsection_C_desc1: "Les deux chambres sont équipées d'une salle de douche privative et d'un WC privatif.",
    subsection_C_desc2: "Sèche-cheveux et peignoirs de bain pour votre confort.",
    subsection_D: "Tarifs (petit déjeuner inclus)",
    "subsection_D_desc0a": "•  85 € /nuit pour le lit double",
    "subsection_D_desc0b": "• + 15 € /nuit pour chaque lit simple supplémentaire",
    "subsection_D_desc1": "-10% à partir de 2 nuits",
    "subsection_D_desc2": "Le devis pour les longs séjours : disponible sur demande",
    option1: "Le petit déjeuner gourmet est inclus dans le prix de la chambre",
    option1_desc: "Il est servi au living ou sur la terrasse couverte : jus de pommes du verger, jus d’oranges pressées, fruits frais, yogourt et œufs de différentes façons, fromage et charcuteries, cake ou galettes et confitures artisanales maison, viennoiseries, céréales, café, thé, lait.",
    old_option1: "Petit-déjeuner sucré-salé simple (+ 5€/pp) ou standard (+10€/pp)",
    old_option1_desc: "Les petits déjeuners sont servis à l'intérieur ou sur la terrasse couverte : jus de pommes du verger, jus d’oranges pressées, yogourt et œufs de différentes façons, fromage et charcuteries, galettes et confitures artisanales maison, viennoiseries, café, thé.",
    option2: "Nous pouvons organiser un service traiteur sur demande, le midi et/ou le soir :",
    option2_url: 'https://www.facebook.com/une.autre.epoque.baisy.thy',
    option2_title: 'Page Facebook',
    option3: "Lit bébé (gratuit)",
    option4: "Lit simple supplémentaire (+15 €/lit)",
    section_localisation: "Localisation & accès",
    car: "Accès aisé aux autoroutes vers Bruxelles - Charleroi - Namur." ,
    flights: "Aéroport de Charleroi (20 km) - Aéroport de Bruxelles (35 km) - Possibilité de navette (sous conditions)",
    trains:  "Train : gares de Ottignies et de Braine-l'Alleud (15 km)",
    address: '25A Rue Chéniat, 1470 Genappe (Baisy-Thy), Belgium',
    section_contact: "Contact",
    contact_cellphone: '+32 475 55 04 51',
    contact_phone: '+32 67 79 03 82',
    contact_fullname: 'Le Chéniat',
    contact_email: 'lecheniat@gmail.com',
    facebook_url: 'https://www.facebook.com/Chambre-dh%C3%B4tes-Le-Ch%C3%A9niat-1523712734377878',
    section_description: 'Hébergement de terroir',
    medium_desc_1: 'Dans un environnement calme et champêtre, ' +
        'cette charmante maison occupe une position centrale au carrefour de plusieurs sites touristiques ' +
        'qui vous permet de découvrir le Brabant wallon.',
    medium_desc_2: 'Le Chéniat vous propose une chambre double capable d\'accueillir jusqu\'à 4 personnes.',
    medium_desc_3: 'Langues parlées : Anglais, Français, Néerlandais',
    ranking: '3 épi(s)',
    section_capacity: 'Capacité',
    n_room: '1*',
    n_simple_bed: '2',
    n_baby_bed: '1',
    n_double_bed: '1',
    n_bathroom: '1',
    n_wc: '1',
    base_capacity: '4',
    section_equipments: 'Equipements et services',
    item1: '- Accès internet (WIFI)',
    item2: '- Draps et linge compris',
    item3: '- Grand Jardin',
    item4: '- Mobilier de jardin',
    item5: '- Parking - borne de recharge 11 kW',
    item6: '- Piscine en plein air; transats et serviettes de bain',
    item7: '- Terrain clos',
    item9: '- Terrasse',
    misc11: '- Chambre d\'hôtes',
    misc12: '- Anglais Français Néerlandais',
    misc13: 'Publication FTPBW',
    misc14: 'Tourisme de mémoire',
    book: 'Réserver',
    section_activities: 'Découvrez le Brabant Wallon',
    section_activities_short: 'Découvertes',
    a1_city: 'Louvain-La-Neuve (15km)',
    a1_list: 'Science Park, Musée Hergé, Musée L, Bois de Lauzelle, Bois des rêves,',
    // a2_city: 'Mont-Saint Guibert (15km)',
    // a2_list: 'AXISPARC,',
    a2_city: 'Wavre (15km)',
    a2_list: 'Walibi,',
    a3_city: 'Villers-La-Ville (3km)',
    a3_list: 'Abbaye, micro-brasserie',
    a3_url0: 'https://villers.be/fr',
    a3_url0_text: 'Site officiel',
    a4_city: 'La Hulpe (15km)',
    a4_list: 'Fondation Folon: musée, Domaine Solvay, château de la Hupe',
    a5_city: 'Parcours de Golf',
    a5_list: 'Rigenée, Bruyère, l\'Empereur, Hotel de Pierpont,...',
    a6_city: 'Randonnées & balades à vélo',
    a6_list: 'Nous vous renseignons sur les réseaux cyclables et les randonnées pédestres et VTT à proximité.',
    a6_url0: 'https://eurovelobelgium.be/eurovelo/5',
    a6_url0_text: 'L\'itinéraire EuroVelo passe à 12 km',
    a6_url1: 'https://www.visorando.com/fr-be/randonnee-villers-la-ville-belgique.html',
    a6_url1_text: 'Itinéraires à Villers-la-Ville - Visorando',
    a6_url2: 'https://www.villers-la-ville.be/vie-locale/tourisme/balades/ilovepdf-merged.pdf',
    a6_url2_text: '(PDF) Balades pédestres à Villers-La-Ville',
    a7_city: 'Waterloo (12km)',
    a7_url0: 'https://visitwallonia.be/fr-be/content/randonnee-pedestre-incontournable-au-coeur-de-la-bataille',
    a7_url0_text: 'Randonnée incontournable sur le champ de Bataille de Waterloo',
    a7_url1: 'https://visitwallonia.be/fr-be/produit/attractions/activites/musees/decouvertes/memorial-1815-champ-de-bataille-de-waterloo/9245',
    a7_url1_text: 'Memorial Waterloo 1815',
    a7_url2_old: 'https://visitwallonia.be/fr-be/content/musee-wellington-waterloo-retour-sur-la-bataille-de-1815',
    a7_url2: 'https://www.museewellington.be/?lang=fr',
    a7_url2_text: 'Musée Wellington',
    a7_url3: 'https://visitwallonia.be/fr-be/content/panorama-de-la-bataille-de-waterloo-une-fresque-a-360',
    a7_url3_text: 'Fresque du panorama de la Bataille de Waterloo',
    a7_url4: 'http://www.waterloo-tourisme.com/fr',
    a7_url4_text: 'Office du tourisme de Waterloo',
    desc_title_1: 'B&B - Chambre d\'hôtes à Baisy-Thy',
    desc_title_2: 'Appartement de charme dans un écrin de verdure',
    old_desc_full_1: 'Dans un cadre champêtre proche de l\'abbaye de Villers-la-Ville, cette charmante maison occupe une position centrale au carrefour de plusieurs sites touristiques du Brabant wallon.\n' +
        'Le Chéniat vous propose une suite de deux chambres capable d\'accueillir jusqu\'à 4 personnes. \n' +
        'Pour vos séjours professionnels, une pause en amoureux ou vos aventures sportives, notre logement vous offre quiétude et confort.\n' +
        'Et lors d\'une escale en famille, le lit bébé vous est offert.',
    desc_full_1: 'A la recherche d\'un logement en Belgique, en Wallonie, en Brabant wallon ?\n' +
        'Le Chéniat, proche de l\'abbaye de Villers-la-Ville, vous propose une suite de deux chambres capable d\'accueillir jusqu\'à 4 personnes. \n' +
        'Pour vos séjours business, une pause en amoureux ou vos aventures sportives, notre logement vous assure calme et confort.\n' +
        'Et lors de vacances en famille, le lit bébé vous est offert.',
    desc_full_2: 'Langues parlées : Anglais, Français, Néerlandais',
    ext1: 'Agréable jardin clos, piscine extérieure sécurisée et terrasse couverte vous procurent bien-être et douceur.',
    ext2: 'Le parking accueille 2 à 3 voitures et est équipé d\'une borne de recharge 11 kW.',
    ext3: 'Vos vélos sont garés dans un local sécurisé et couvert.',
    living0: 'Une salle de séjour vous est dédiée :',
    living1: 'Un living spacieux et lumineux de 32 m²',
    living2: 'Bibliothèque et Salon de détente',
    sdb1: 'équipées d\'une salle de douche et d\'un WC privatif.',
    sdb2: 'Sèche-cheveux et peignoirs de bain pour votre confort.',
    desk: 'Wi-Fi, bureau et écran à disposition pour les accros au travail.',
    "ctitle": "Demande de réservation",
    "clastname": "Nom",
    "cfirstname": "Prénom",
    "caddress": "Adresse",
    "ccity": "Ville",
    "ccountry": "Pays",
    "cpcode": "Code postal",
    "ctel": "Téléphone",
    "cemail": "Email",
    "cstart": "Date d'arrivée",
    "cnights": "Nombre de nuitées",
    "cpersons": "Nombre de personnes",
    "cobject": "Sujet",
    "cmessage": "Message",
    "csend": "Envoyer",
    "ccancel": "Annuler",
}
