import {createBrowserRouter, RouterProvider} from "react-router-dom"
import {App} from "./App";
import {LangRoot} from "../helpers/useTranslation";
const router = createBrowserRouter([
    {
        path: "/",
        element: <LangRoot><App /></LangRoot>,
    },
    {
        path: "/:locale",
        element: <LangRoot><App /></LangRoot>,
    },
]);
export const Routes = () => <RouterProvider router={router}/>

