import React, {useEffect, useRef, useState} from 'react';
import {Tw} from "../../helpers/Tw";
import {TranslationTypes, useTranslation} from "../../helpers/useTranslation";

// TODO not working on Safari & chrome windows
interface Props extends Tw {
    children?: any
}

export const Slide = ({active, Img} : any) => {
    return (
        <div
            // data-mdb-ride='true'
            // data-mdb-interval='2500'
            className={`carousel-item ${active ? 'active' : ''} relative float-left w-full h-[16rem] sm:h-[20rem] md:h-[24rem] lg:h-[36rem] xl:h-[42rem]`}>
            <img
                src={Img}
                className='w-full h-full object-cover'
                alt="..."
            />
        </div>
    )
}


/**
 * https://tailwind-elements.com/docs/standard/components/carousel/
 * @param p
 * @constructor
 */
export const Carousel =  (p : Props) => {
    const {t, setLanguage} : TranslationTypes = useTranslation();
    const ref = useRef(null);
    const [load, setLoad] = useState('')
    // useEffect(() => {
    //     // console.log('Carou', ref.current)
    //     // // @ts-ignore
    //     // ref?.current?.carousel?.('cycle');
    //     // var myCarousel = document.querySelector('.carousel');
    //     // // var carousel = new bootstrap.Carousel(myCarousel)
    //     // console.log({myCarousel})
    //     setTimeout(() => setLoad('carousel'), 500)
    //
    // }, [])
    return (
        <div id="carouselExampleCaptions"
             className="carousel slide relative"
             data-bs-ride={'carousel'}
             data-bs-interval='2500'
             ref={ref}
             // data-bs-touch='true'
             // data-bs-pause='false'
             // data-bs-wrap='true'
        >
            {/**/}
            <div className='absolute bottom-1/3 sm:bottom-1/2 w-full z-10 px-24'>
                <h1 className='text-center font-bold text-2xl sm:text-4xl lg:text-5xl text-white text-shadow shadow-black'>
                    {/*{t('title')}*/}
                    {t('desc_title_1')}
                </h1>
                <p className='text-center text-base sm:text-lg lg:text-xl text-white text-shadow shadow-black'>
                    {/*{t('short_desc')}*/}
                    {t('desc_title_2')}
                </p>
            </div>
            {/**/}
            <div className="carousel-inner relative w-full overflow-hidden">
                {p.children}
            </div>
            {/**/}
            <button
                className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}
