import {TKeys} from "./index";
// @ts-ignore
export const contentEn: Record<TKeys, string> = {
    "short_title": "Le Chéniat",
    "title": "Bed and breakfast - Le Chéniat",
    "short_desc": "Guest room in Walloon Brabant spacious and warm",
    "_figcaption": "Official B&B classification in Wallonia: 3 stars",
    "figcaption": "Recognized by the General Commission for Tourism: Bed and Breakfast classified 3 Epis",
    figcaption_bike: 'Label "Bikes Welcome"',
    "section_openings": "Welcoming hours",
    "hours_1": "Open all year",
    "hours_2": "Arrival between 5:00 p.m. and 9:00 p.m.",
    "hours_3": "Departure no later than 11:00 a.m.",
    "section_rooms": "Our two bedroom suite",
    "section_rooms_short": "Bedrooms",
    "subsection_A": "Master bedroom",
    "subsection_A_desc": "A spacious 30 m² bedroom with a double bed and an optional single bed",
    "subsection_B": "Annex room",
    "subsection_B_desc": "An adjoining bedroom of 9 m² with a single bed and a cot as well as a changing table",
    "section_options": "The options",
    section_options_short: "Options",
    "subsection_C": "Private bathroom",
    "subsection_C_desc1": "The two bedrooms are equipped with a private shower room and a private toilet.",
    "subsection_C_desc2": "Hairdryer and bathrobes for your comfort.",
    "subsection_D": "Prices (breakfast included)",
    "subsection_D_desc0a": "• €85 /night for the double bed",
    "subsection_D_desc0b": "• + €15 /night for each additional single bed",
    "subsection_D_desc1": "-10% from 2 nights",
    "subsection_D_desc2": "Quote for long stays : available on request",
    "option1": "Gourmet breakfast is included in the price of the room",
    "option1_desc": "It is served in the living room or on the covered terrace: apple juice from the orchard, squeezed orange juice, fresh fruit, yogurt and eggs in different ways, cheese and cold cuts, cake or pancakes and homemade jams, pastries, cereals, coffee , tea, milk.",
    "old_option1": "Simple sweet and savory breakfast (+ 5€/pp) or standard (+10€/pp)",
    "old_option1_desc": "Breakfasts are served inside or on the covered terrace: apple juice from the orchard, pressed orange juice, yogurt and eggs in different ways, cheese and charcuterie, home-made pancakes and jams, pastries, coffee, tea.",
    "option2": "We can organize a catering service on request, for lunch and/or evening: ",
    option2_url: 'https://www.facebook.com/une.autre.epoque.baisy.thy',
    option2_title: 'Facebook page',
    "option3": "Cot (free)",
    "option4": "Additional single bed (+15 €/bed)",
    "section_localisation": "Location & access",
    "car": "Easy access to motorways to Brussels - Charleroi - Namur.",
    "flights": "Charleroi Airport (20 km) - Brussels Airport (35 km) - Possibility of shuttle (subject to conditions)",
    "trains": "Train: Ottignies and Braine-l'Alleud stations (15 km)",
    "address": "25A Rue Chéniat, 1470 Genappe (Baisy-Thy), Belgium",
    "section_contact": "Contact",
    "contact_cellphone": "+32 475 55 04 51",
    "contact_phone": "+32 67 79 03 82",
    "contact_fullname": "Le Chéniat",
    "contact_email": "lecheniat@gmail.com",
    "facebook_url": "https://www.facebook.com/Chambre-dh%C3%B4tes-Le-Ch%C3%A9niat-1523712734377878",
    "section_description": "The accommodation",
    "medium_desc_1": "In a calm and rural environment, this charming house occupies a central position at the crossroads of several tourist sites which allows you to discover Walloon Brabant.",
    "medium_desc_2": "Le Chéniat offers a double room capable of accommodating up to 4 people.",
    "medium_desc_3": "Languages spoken: English, French, Dutch",
    "ranking": "3 stars(s)",
    "section_capacity": "Capacity",
    "n_room": "1*",
    "n_simple_bed": "2",
    "n_baby_bed": "1",
    "n_double_bed": "1",
    "n_bathroom": "1",
    "n_wc": "1",
    "base_capacity": "4",
    "section_equipments": "Facilities and services",
    "item1": "- Internet access (WIFI)",
    "item2": "- Sheets and towels included",
    "item3": "- Large garden",
    "item4": "- Garden furniture",
    "item5": "- Parking - 11 kW charging station",
    "item6": "- Outdoor swimming pool; deckchairs and bath towels",
    "item7": "- Enclosed land",
    "item9": "- Terrace",
    "misc11": "- Bed and breakfast",
    "misc12": "- English French Dutch",
    "misc13": "FTPBW Publishing",
    "misc14": "Memory tourism",
    "book": "Book",
    "section_activities": "Discover the region",
    section_activities_short: 'Discoveries',
    "a1_city": "Louvain-La-Neuve (15km)",
    "a1_list": "Science Park, Hergé Museum, L Museum, Bois de Lauzelle,  Bois des Rêves (“Woods of Dreams”)",
    "a2_city": "Wavre (15km)",
    "a2_list": "Walibi,",
    "a3_city": "Villers-La-Ville (3km)",
    "a3_list": "Abbey, micro-brewery,",
    a3_url0: 'https://villers.be/en',
    a3_url0_text: 'Official website',
    "a4_city": "La Hulpe (15km)",
    "a4_list": "Folon Foundation: museum, Domaine Solvay, Château de la Hupe",
    "a5_city": "Golf course",
    "a5_list": "Rigenée, Bruyère, the Emperor, Hotel de Pierpont,...",
    "a6_city": "Hikes and bike rides",
    "a6_url0": "https://eurovelobelgium.be/eurovelo/5",
    "a6_url0_text": "The EuroVelo route is 12 km away",
    "a6_url1": "https://www.visorando.com/fr-be/randonnee-villers-la-ville-belgique.html",
    "a6_url1_text": 'Routes in Villers-la-Ville - Visorando',
    "a6_url2": "https://www.villers-la-ville.be/vie-locale/tourisme/balades/ilovepdf-merged.pdf",
    "a6_url2_text": '(PDF) Walks in Villers-La-Ville',
    "a6_list": "We inform you about the cycling networks and the hiking and mountain biking nearby.",    a7_city: 'Waterloo (12km)',
    a7_url0: 'https://visitwallonia.be/fr-be/content/randonnee-pedestre-incontournable-au-coeur-de-la-bataille',
    a7_url0_text: 'Unmissable walk on the Battlefield of Waterloo',
    a7_url1: 'https://visitwallonia.be/fr-be/produit/attractions/activites/musees/decouvertes/memorial-1815-champ-de-bataille-de-waterloo/9245',
    a7_url1_text: 'Memorial Waterloo 1815',
    a7_url2_old: 'https://visitwallonia.be/fr-be/content/musee-wellington-waterloo-retour-sur-la-bataille-de-1815',
    a7_url2: "https://www.museewellington.be/?lang=en",
    a7_url2_text: 'Wellington Museum',
    a7_url3: 'https://visitwallonia.be/fr-be/content/panorama-de-la-bataille-de-waterloo-une-fresque-a-360',
    a7_url3_text: 'Fresco of the panorama of the Battle of Waterloo',
    a7_url4: 'http://www.waterloo-tourisme.com/en',
    a7_url4_text: 'Waterloo Tourist Office',
    "desc_title_1": "B&B - Guesthouse in Baisy-Thy",
    "desc_title_2": "Charming apartment in lush greenery",
    "old_desc_full_1": "In a rural setting near the abbey of Villers-la-Ville, this charming house occupies a central position at the crossroads of several tourist sites in Walloon Brabant.\nLe Chéniat offers a two-bedroom suite capable of accommodating up to 4 people.\nFor your professional stays, a romantic break or your sporting adventures, our accommodation offers you peace and comfort.\nAnd during a family stopover, the cot is offered to you.",
    "desc_full_1": "Looking for accommodation in Belgium, Wallonia, Walloon Brabant?\nLe Chéniat, near the abbey of Villers-la-Ville, offers a two-bedroom suite capable of accommodating up to 4 people.\nFor your business stays, a romantic break or your sporting adventures, our accommodation ensures peace and comfort.\nAnd during family holidays, the cot is offered to you.",
    "desc_full_2": "Languages spoken: English, French, Dutch",
    "ext1": "Pleasant enclosed garden, secure outdoor swimming pool and covered terrace provide you with well-being and comfort.",
    "ext2": "The car park accommodates 2 to 3 cars and is equipped with an 11 kW charging station.",
    "ext3": "Your bikes are parked in a secure and covered place.",
    "living0": "A living room is dedicated to you:",
    "living1": "A spacious and bright living room of 32 m²",
    "living2": "Library and relaxation room",
    "sdb1": "equipped with a shower room and a private WC.",
    "sdb2": "Hairdryer and bathrobes for your comfort.",
    "desk": "Wi-Fi, desk and screen available for workaholics.",
    "ctitle": "Request booking",
    "clastname": "Last Name",
    "cfirstname": "First Name",
    "caddress": "Address",
    "ccity": "City",
    "cpcode": "Postal code",
    "ccountry": "Country",
    "ctel": "Phone",
    "cemail": "Email",
    "cstart": "Arrival date",
    "cnights": "Number of night(s)",
    "cpersons": "Number of people",
    "cobject": "Object",
    "cmessage": "Message",
    "csend": "Send",
    "ccancel": "Cancel",
}
