import React from 'react';
import {TranslationTypes, useTranslation} from "../../helpers/useTranslation";
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";

interface Props {
    children?: any;
}
export const Footer =  (p : Props) => {
    const {children} = p;
    const {t, setLanguage} : TranslationTypes = useTranslation();

    return (
        <footer id="footer" className="bg-gray-100 text-gray-600 mt-8">
            <section className="mx-auto flex max-w-5xl flex-col p-4 sm:flex-row sm:justify-between">
                <address className='flex-2 mr-10'>
                    <h2 className='text-lg mb-2'>{t('short_title')}</h2>
                    <p>
                        {t('short_desc')}
                    </p>
                    <p>
                        {t('address')}<br/>
                    </p>
                </address>
                <nav className="hidden md:flex flex-1 flex-col gap-2 self-center" aria-label="footer">
                    <span><AiOutlineMail className='inline text-xl align-text-bottom'/> <a className='underline' href={"mailto:"+('contact_email')}>{t('contact_email')}</a><br/></span>
                    <span><AiOutlinePhone className='inline text-xl align-text-bottom'/> <a className='underline' href={"tel:+"+t('contact_cellphone')}>{t('contact_cellphone')}</a></span>
                    <span><AiOutlinePhone className='inline text-xl align-text-bottom'/> <a className='underline' href={"tel:+"+t('contact_phone')}>{t('contact_phone')}</a></span>
                    {/*Fairfield, New Jersey 12345-5555<br/>*/}

                    {/*<a href="#rockets" className="hover:opacity-90">Our Rockets</a>*/}
                    {/*<a href="#imonials" className="hover:opacity-90">Testimonials</a>*/}
                    {/*<a href="#contact" className="hover:opacity-90">Contact Us</a>*/}
                </nav>
                <hr className='sm:hidden my-3 w-3/4 mx-auto' />
                <div className="flex flex-1 flex-col sm:gap-2">
                    <p className="text-right">Copyright &copy; <span id="year">2023</span></p>
                    <a className="text-right text-gray-600 font-semibold hover:underline" href="https://www.linkedin.com/in/ludovic-gustin/">Lgu</a>
                </div>
            </section>
        </footer>
    )
}
