import React from "react";
import {TranslationTypes, useTranslation} from "../../helpers/useTranslation";

/**
 * <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2532.633996411869!2d4.483996951603188!3d50.59675577939513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3d4b3940076c7%3A0xc0f69eedba59570d!2sRue%20Ch%C3%A9niat%2025%2C%201470%20Genappe!5e0!3m2!1sfr!2sbe!4v1676241546260!5m2!1sfr!2sbe" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 * @param greyed
 * @constructor
 */
export const GMaps = ({ greyed = false }: { greyed: boolean }) => {
    const {t, setLanguage} : TranslationTypes = useTranslation();
    return (
        <div id="map" className="w3-container w3-margin-bottom ">
            <div
                className={
                    "w3-row-padding w3-padding-64 " + (greyed ? "w3-light-grey" : "")
                }
                // style={{backgroundColor: greyed ? 'lightblue' : 'white'}}
            >
                <div className="w3-content">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2532.633996411869!2d4.483996951603188!3d50.59675577939513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3d4b3940076c7%3A0xc0f69eedba59570d!2sRue%20Ch%C3%A9niat%2025%2C%201470%20Genappe!5e0!3m2!1sfr!2sbe!4v1676241546260!5m2!1sfr!2sbe"
                        width="100%"
                        height="400px"
                        frameBorder="0"
                        style={{ display: "block", border: 0, margin: "0 auto" }}
                        allowFullScreen={false}
                        aria-hidden="false"
                        tabIndex={0}
                    />
                </div>
            </div>
        </div>
    )
}
