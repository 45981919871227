import React, {useState} from 'react';
import {Header} from './Header';
import {Article} from "./_containers/article";
import {Section} from "./_containers/section";
import {Footer} from "./Footer";
import {Main} from "./_containers/main";
import {twDiv} from "../helpers";
import {Carousel, Slide} from "./Carousel";
import {GMaps} from "./GMap/GMaps";
import {GalleryItem} from "./GalleryItem";
import {TranslationTypes, useTranslation} from "../helpers/useTranslation";


export const App = () => {
    const MainContainer = twDiv`px-4 sm:px-24 md:px-36 lg:px-48`;
    const {t} : TranslationTypes = useTranslation();
    const [modalShow, setModalShow] = useState(false);

    return (
            <div className='min-h-screen bg-white text-gray-700'>
                <Header modalShow={modalShow} setModalShow={setModalShow}/>
                <Carousel>
                    <Slide active Img={require('../assets/img/garden/photo8.jpeg')}/>
                    <Slide Img={require('../assets/img/chambres/jour_IMG_7958.JPG')}/>
                    <Slide Img={require('../assets/img/chambres/IMG_20170826_173728.jpg')}/>
                    <Slide Img={require('../assets/img/garden/photo5.jpeg')}/>
                    <Slide Img={require('../assets/img/env/IMG_20170312_143810.jpg')}/>
                </Carousel>
                <MainContainer>
                    <Main>
                        <Section title={t('section_description')}>
                            <Article>
                                <p>{t('desc_full_1')}</p>
                                <br/>
                                <p>{t('desc_full_2')}</p>
                                <br/>
                                <p>{t('ext1')}</p>
                                <p>{t('ext2')}</p>
                                <p>{t('ext3')}</p>
                                <div className="container p-5 mx-auto">
                                    <div className="flex flex-row flex-wrap -m-1 md:-m-2">
                                        <GalleryItem size='1'
                                                     src={require('../assets/img/garden/photo5.jpeg')}
                                                     halfSrc={require('../assets/img/garden/half_photo5.jpeg')}/>
                                        <GalleryItem size='1' src={require('../assets/img/garden/photo6.jpg')}/>
                                        <GalleryItem size='1' src={require('../assets/img/garden/photo8.jpeg')}/>
                                        <GalleryItem size='1' src={require('../assets/img/chambres/IMG_20170826_173728.jpg')}/>
                                        <GalleryItem size='1' src={require('../assets/img/env/IMG_20170826_180120.jpg')}/>
                                    </div>
                                </div>
                                <p>{t('living0')}</p>
                                <p>{t('living1')}</p>
                                <p>{t('living2')}</p>
                                <div className="container p-5 mx-auto">
                                    <div className="flex flex-row flex-wrap -m-1 md:-m-2">
                                        <GalleryItem size='2' src={require('../assets/img/living/photo4.jpeg')}/>
                                        <GalleryItem size='1' src={require('../assets/img/living/IMG_7944.JPG')}/>
                                        <GalleryItem size='2' src={require('../assets/img/living/IMG_7964.JPG')}/>
                                        <div className='flex-1'/>
                                    </div>
                                </div>
                                <figure className='mx-auto text-center mt-5'>
                                    <div className='mx-auto mb-5  flex flex-row justify-center items-center gap-20'>
                                        <img src={require('../assets/img/3epis.png')} alt="3epis" className='w-20'/>
                                        <img src={require('../assets/img/bienvenue_velo.png')} alt="bike welcome" className='w-20'/>
                                    </div>
                                    <figcaption className='text-xs italic'>{t('figcaption')}</figcaption>
                                    <figcaption className='text-xs italic'>{t('figcaption_bike')}</figcaption>
                                </figure>
                            </Article>
                        </Section>
                    </Main>
                    <Section title={t('section_openings')}>
                        <Article>
                            <p>{t('hours_1')}</p>
                            <p>{t('hours_2')}</p>
                            <p>{t('hours_3')}</p>
                        </Article>
                    </Section>
                    <Section id='bedrooms' title={t('section_rooms')}>
                        <Article>
                            <p><b>• {t('subsection_A')}</b></p>
                            <p>{t('subsection_A_desc')}</p>

                            <div className="container p-5 mx-auto">
                                <div className="flex flex-wrap -m-1 md:-m-2">
                                    <GalleryItem size='2' src={require('../assets/img/chambres/jour_IMG_7958.JPG')}/>
                                    <GalleryItem size='2' src={require('../assets/img/chambres/IMG_7936.jpeg')}/>
                                    <GalleryItem size='1' src={require('../assets/img/chambres/IMG_7937.jpeg')}/>
                                    <GalleryItem size='1' src={require('../assets/img/chambres/IMG_7959.JPG')}/>
                                </div>
                            </div>

                            <p>{t('desk')}</p>

                            <br/>
                            <p><b>• {t('subsection_B')}</b></p>
                            <p>{t('subsection_B_desc')}</p>

                            <div className="container p-5 mx-auto">
                                <div className="flex flex-wrap -m-1 md:-m-2">
                                    <GalleryItem size='1' src={require('../assets/img/chambres/photo3.jpeg')}/>
                                    <div className='flex-1'/>
                                    <div className='flex-1'/>
                                </div>
                            </div>

                            <p><b>• {t('subsection_C')}</b></p>
                            <p>{t('subsection_C_desc1')}</p>
                            <p>{t('subsection_C_desc2')}</p>
                            <div className="container p-5 mx-auto">
                                <div className="flex flex-wrap -m-1 md:-m-2">
                                    <GalleryItem size='2' src={require('../assets/img/sdb/half_jour_IMG_7954.JPG')}/>
                                    <GalleryItem size='2' src={require('../assets/img/sdb/jour_IMG_7951.JPG')}/>
                                    <GalleryItem size='2' src={require('../assets/img/sdb/jour_IMG_7953.JPG')}/>
                                </div>
                            </div>
                            <p><b>• {t('subsection_D')}</b></p>
                            <p>{t('subsection_D_desc0a')}</p>
                            <p>{t('subsection_D_desc0b')}</p>
                            <br/>
                            <p>{t('subsection_D_desc1')}</p>
                            <p>{t('subsection_D_desc2')}</p>
                        </Article>
                    </Section>
                    <Section id='options' title={t('section_options')}>
                        <Article>
                            <p><b>• {t('option1')}</b></p>
                            <br/>
                            <p>{t('option1_desc')}</p>
                            <div className="container p-5 mx-auto">
                                <div className="flex flex-wrap -m-1 md:-m-2">
                                    <GalleryItem size='1' src={require('../assets/img/living/IMG_7947.JPG')}/>
                                    <GalleryItem size='1' src={require('../assets/img/living/IMG_7945.JPG')}/>
                                    <GalleryItem size='1' src={require('../assets/img/garden/IMG_7973.JPG')}/>
                                </div>
                            </div>
                            <p><b>• {t('option2')}</b> <a className='underline' href={t('option2_url')}>{t('option2_title')}</a></p>
                            <p><b>• {t('option3')}</b></p>
                            <p><b>• {t('option4')}</b></p>
                        </Article>
                    </Section>
                    <Section id='activities' title={t('section_activities')}>
                        <Article>
                            <ul>
                                <li>
                                    <b>{t('a3_city')}</b>: {t('a3_list')} <a className='underline' href={t('a3_url0')}>{t('a3_url0_text')}</a>
                                </li>
                                <li><b>{t('a7_city')}</b>:
                                    {' '}
                                    {/* - <a className='underline' href={t('a7_url0')}>{t('a7_url0_text')}</a> */}
                                    <a className='underline' href={t('a7_url4')}>{t('a7_url4_text')}</a>
                                    {' '}
                                    {/* - <a className='underline' href={t('a7_url1')}>{t('a7_url1_text')}</a> */}
                                    <a className='underline' href={t('a7_url2')}>{t('a7_url2_text')}</a>
                                    {/* - <a className='underline' href={t('a7_url3')}>{t('a7_url3_text')}</a> */}
                                </li>
                                <li><b>{t('a1_city')}</b>: {t('a1_list')}</li>
                                <li><b>{t('a2_city')}</b>: {t('a2_list')}</li>
                                <li><b>{t('a4_city')}</b>: {t('a4_list')}</li>
                                <br/>
                                <li><b>{t('a5_city')}</b>: {t('a5_list')}</li>
                                <li><b>{t('a6_city')}</b>: {t('a6_list')}
                                    <br/>
                                    - <a className='underline' href={t('a6_url0')}>{t('a6_url0_text')}</a>
                                    <br/>
                                    - <a className='underline' href={t('a6_url1')}>{t('a6_url1_text')}</a>
                                    <br/>
                                    - <a className='underline' href={t('a6_url2')}>{t('a6_url2_text')}</a>
                                </li>
                                <div className="container p-5 mx-auto">
                                    <div className="flex flex-wrap -m-1 md:-m-2">
                                        <GalleryItem size='2'
                                                     src={require('../assets/img/env/IMG_20170312_143810.jpg')}/>
                                        <GalleryItem size='1' src={require('../assets/img/env/IMG_7972.JPG')}/>
                                        <GalleryItem size='2' src={require('../assets/img/env/image (4).png')}/>
                                        <div className='flex-1'/>
                                    </div>
                                </div>
                            </ul>
                        </Article>
                    </Section>
                    <Section title={t('section_equipments')}>
                        <Article>
                            <ul>
                                <li>{t('item1')}</li>
                                <li>{t('item2')}</li>
                                <li>{t('item3')}</li>
                                <li>{t('item4')}</li>
                                <li>{t('item5')}</li>
                                <li>{t('item6')}</li>
                                <li>{t('item7')}</li>
                                <li>{t('item9')}</li>
                            </ul>
                        </Article>
                    </Section>
                    <Section title={t('section_localisation')}>
                        <Article>
                            <address className='font-bold mb-5'>{t('address')}</address>
                            <ul className='mb-5'>
                                <li> - {t('car')}</li>
                                <li> - {t('flights')}</li>
                                <li> - {t('trains')}</li>
                            </ul>
                        </Article>
                        <GMaps greyed={false}/>
                    </Section>
                    <section className="container mx-auto p-10 flex">
                            {/* CTA */}
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                                className='mx-auto text-white text-xl lg:text-2xl hover:opacity-90 bg-red-500 p-2 rounded'
                                href='#'
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setModalShow(true)
                                }}
                            >
                                {t('book')}
                            </a>
                    </section>
                </MainContainer>
                <Footer/>
            </div>
    );
}
