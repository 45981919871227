import React from 'react';
import {twP} from "../../../helpers";
import {Container} from "../container";

interface Props {
    children: any;
    title?: string;
    id?: string;
}
export const Section =  (p : Props) => {
    const {children} = p;
    const BigText = twP`text-2xl text-gray-700 font-bold mb-5 mt-5`;
    return (
        <section id={p.id} className="container -scroll-mt-1">
            {p.title && <BigText>{p.title}</BigText>}
            <Container>
                {children}
            </Container>
        </section>
    );
}
