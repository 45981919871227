import React, {useState} from 'react';
import {AppLangs, TranslationTypes, useTranslation} from "../../helpers/useTranslation";
import {AiOutlinePhone, AiOutlineMail} from "react-icons/ai";
import {ContactModal} from "../ContactModal";
import {Link} from "react-router-dom";

const cB= '#751A41'
const cC= '#88345A'
const cA= '#862B54'
const cD= '#832951'
interface Props {
    children?: any;
    modalShow: boolean;
    setModalShow: (v: boolean) => void;
}
export const Header =  (p : Props) => {
    const {children, setModalShow, modalShow} = p;
    const {t, setLanguage, language} : TranslationTypes = useTranslation();

    const [showMenu, setShowMenu] = useState(false);
    return (
        <>
            <header role='banner' className='text-white sticky top-0 z-20' style={{backgroundColor:'#832951'}}>
            <ContactModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
                <div className='flex bg-white text-gray-700'>
                    <section className='max-w-5xl mx-auto p-2 flex flex-1 justify-end items-center'>
                        <nav className='block space-x-8 text-md' aria-label='main'>
                         <span className='space-x-3'>
                            <a
                                className='hidden md:inline text-base hover:opacity-90 hover:underline'
                                href={'mailto:'+t('contact_email')}
                            >
                                <AiOutlineMail className='inline text-xl align-text-bottom'/> {t('contact_email')}
                            </a>
                            <a
                                className='hidden md:inline text-base hover:opacity-90 hover:underline'
                                href={'tel:'+t('contact_cellphone')}
                            >
                                <AiOutlinePhone className='inline text-xl align-text-bottom'/> {t('contact_cellphone')}
                            </a>
                            <Link
                                className='text-sm hover:opacity-90 hover:underline'
                                to={'/fr'}
                                // onClick={(e) => { e.preventDefault(); e.stopPropagation(); setLanguage(AppLangs.fr)}}
                            >
                                🇫🇷 {AppLangs.fr}
                            </Link>
                            <Link
                                className='text-sm hover:opacity-90 hover:underline'
                                to={'/en'}
                            >
                                🇺🇸 {AppLangs.en}
                            </Link>
                            <Link
                                className='text-sm hover:opacity-90 hover:underline'
                                to={'/nl'}
                            >
                                🇳🇱 {AppLangs.nl}
                            </Link>
                            <a
                                className='text-center hover:opacity-90 bg-red-500 p-1 rounded text-white'
                                href='#'
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowMenu(false);
                                    setModalShow(true)
                                }}
                            >
                                {t('book')}
                            </a>
                        </span>
                        </nav>
                    </section>
                </div>

        <section className='max-w-5xl mx-auto p-4 flex justify-between items-center'>
            <a href='/' className='flex flex-row justify-center items-center'>
                <h1 className='text-xl lg:text-3xl font-medium'>
                    {t('short_title')}
                </h1>
                <img src={require('../../assets/img/last-logo.png')} alt="3epis" className='mx-auto w-12 ml-5'/>
            </a>
            <div>
                {/* MOBILE */}
                <button onClick={() => setShowMenu(t => !t)} className='text-3xl md:hidden px-2 pb-1 focus:outline-none cursor-pointer border-solid border border-white rounded-xl'>
                    menu &#9776;
                </button>
                {/* MOBILE + DESK */}
                <nav className='hidden md:block space-x-8 text-xl' aria-label='main'>
                    <a
                        className='hover:opacity-90 hover:underline'
                        href={'#bedrooms'}
                    >
                        {t('section_rooms_short')}
                    </a>
                    <a
                        className='hover:opacity-90 hover:underline'
                        href={'#options'}
                    >
                        {t('section_options_short')}
                    </a>
                    <a
                        className='hover:opacity-90 hover:underline'
                        href={'#activities'}
                    >
                        {t('section_activities_short')}
                    </a>
                    {/*<a*/}
                    {/*    className='text-base hover:opacity-90 hover:underline'*/}
                    {/*    href={'mailto:'+t('contact_email')}*/}
                    {/*>*/}
                    {/*    <AiOutlineMail className='inline text-xl align-text-bottom'/> {t('contact_email')}*/}
                    {/*</a>*/}
                    {/*<a*/}
                    {/*    className='text-base hover:opacity-90 hover:underline'*/}
                    {/*    href={'tel:'+t('contact_cellphone')}*/}
                    {/*>*/}
                    {/*    <AiOutlinePhone className='inline text-xl align-text-bottom'/> {t('contact_cellphone')}*/}
                    {/*</a>*/}
                    {/*<span className='space-x-2'>*/}
                    {/*    <a*/}
                    {/*        className='text-sm hover:opacity-90 hover:underline'*/}
                    {/*        href='#'*/}
                    {/*        onClick={(e) => { e.preventDefault(); e.stopPropagation(); setLanguage(AppLangs.fr)}}*/}
                    {/*    >*/}
                    {/*        🇫🇷 {AppLangs.fr}*/}
                    {/*    </a>*/}
                    {/*    <a*/}
                    {/*        className='text-sm hover:opacity-90 hover:underline'*/}
                    {/*        href='#'*/}
                    {/*        onClick={(e) => { e.preventDefault(); e.stopPropagation(); setLanguage(AppLangs.en)}}*/}
                    {/*    >*/}
                    {/*        🇺🇸 {AppLangs.en}*/}
                    {/*    </a>*/}
                    {/*    <a*/}
                    {/*        className='text-sm hover:opacity-90 hover:underline'*/}
                    {/*        href='#'*/}
                    {/*        onClick={(e) => { e.preventDefault(); e.stopPropagation(); setLanguage(AppLangs.nl)}}*/}
                    {/*    >*/}
                    {/*        🇳🇱 {AppLangs.nl}*/}
                    {/*    </a>*/}
                    {/*</span>*/}
                    {/* CTA */}
                    {/*<a*/}
                    {/*    className='text-lg lg:text-xl hover:opacity-90 bg-red-500 p-2 rounded'*/}
                    {/*    href='#'*/}
                    {/*    onClick={(e) => {*/}
                    {/*        e.preventDefault();*/}
                    {/*        e.stopPropagation();*/}
                    {/*        setModalShow(true)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {t('book')}*/}
                    {/*</a>*/}
                </nav>
            </div>
        </section>
        {/* MOBILE */}
        <section id="mobile-menu"
                 onClick={() => setShowMenu(t => !t)}
                 className={`top-68 justify-content-center absolute ${showMenu ? 'flex' : 'hidden'} w-full origin-top animate-open-menu flex-col bg-black text-3xl`}>
            <nav className="flex min-h-screen flex-col items-center py-8" aria-label="mobile">
                <a
                    className='w-full py-6 text-center hover:opacity-90 hover:underline'
                    href={'#bedrooms'}
                >
                    {t('section_rooms_short')}
                </a>
                <a
                    className='w-full py-6 text-center hover:opacity-90 hover:underline'
                    href={'#options'}
                >
                    {t('section_options_short')}
                </a>
                <a
                    className='w-full py-6 text-center hover:opacity-90 hover:underline'
                    href={'#activities'}
                >
                    {t('section_activities_short')}
                </a>
                {/*<a*/}
                {/*    className='w-full py-6 text-center hover:opacity-90 hover:underline'*/}
                {/*    href={'mailto:'+t('contact_email')}*/}
                {/*>*/}
                {/*    <AiOutlineMail className='inline text-3xl align-text-bottom'/>️ {t('contact_email')}*/}
                {/*</a>*/}
                {/*<a*/}
                {/*    className='w-full py-6 text-center hover:opacity-90 hover:underline'*/}
                {/*    href={'tel:'+t('contact_cellphone')}*/}
                {/*>*/}
                {/*    <AiOutlinePhone className='inline text-3xl align-text-bottom'/> {t('contact_cellphone')}*/}
                {/*</a>*/}
                {/*<span className='w-full py-6 text-center space-x-2'>*/}
                {/*        <a*/}
                {/*            className='hover:opacity-90 hover:underline'*/}
                {/*            href='#'*/}
                {/*            onClick={(e) => { e.preventDefault(); e.stopPropagation(); setLanguage(AppLangs.fr)}}*/}
                {/*        >*/}
                {/*            🇫🇷 {AppLangs.fr}*/}
                {/*        </a>*/}
                {/*        <a*/}
                {/*            className='hover:opacity-90 hover:underline'*/}
                {/*            href='#'*/}
                {/*            onClick={(e) => { e.preventDefault(); e.stopPropagation(); setLanguage(AppLangs.en)}}*/}
                {/*        >*/}
                {/*            🇺🇸 {AppLangs.en}*/}
                {/*        </a>*/}
                {/*        <a*/}
                {/*            className='hover:opacity-90 hover:underline'*/}
                {/*            href='#'*/}
                {/*            onClick={(e) => { e.preventDefault(); e.stopPropagation(); setLanguage(AppLangs.nl)}}*/}
                {/*        >*/}
                {/*            🇳🇱 {AppLangs.nl}*/}
                {/*        </a>*/}
                {/*    </span>*/}
                {/*<a*/}
                {/*    className='text-center hover:opacity-90 bg-red-500 p-2 rounded'*/}
                {/*    href='#'*/}
                {/*    onClick={(e) => {*/}
                {/*        e.preventDefault();*/}
                {/*        e.stopPropagation();*/}
                {/*        setShowMenu(false);*/}
                {/*        setModalShow(true)*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {t('book')}*/}
                {/*</a>*/}
            </nav>
        </section>
    </header>
        </>);
}
