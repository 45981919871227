import {PhotoView} from "react-photo-view";
import React from "react";

const ImgWithZoom = ({src, halfSrc = src, ...p} : any) => <PhotoView src={src}><img {...p} src={halfSrc} /></PhotoView>
export const GalleryItem = ({src,  halfSrc, size ='1/3'} : any) => {
    //  <div className={`flex flex-wrap w-${size}`}>
    return (
        <div className={`flex flex-wrap flex-${size}`}>
            <div className="w-full h-[6rem] sm:h-[6rem] md:h-[8rem] lg:h-[10rem] xl:h-[12rem] 2xl:h-[15rem] p-1 md:p-2">
                <ImgWithZoom alt="gallery" className="block object-cover object-center w-full h-full rounded-lg cursor-pointer"
                             halfSrc={halfSrc}
                             src={src}/>
            </div>
        </div>
    )
}
