import React from 'react';
import {Tw} from "../../../helpers/Tw";

interface Props extends Tw {
    children: any;
}
export const Main =  (p : Props) => {
    const {children} = p;
    return (<main className={p.tw}>
        {children}
    </main>);
}
