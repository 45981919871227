import React, {useState} from 'react';
import {Tw} from "../../helpers/Tw";
import {TranslationTypes, useTranslation} from "../../helpers/useTranslation";

interface Props extends Tw {
    children?: any;
    onHide?: any;
    show?: any;
}

const Fields =  [
    'cstart',
    'cnights', 'cpersons', 'cfirstname', 'clastname', 'cemail', 'ctel', 'caddress', 'ccountry', 'ccity', 'cpcode', 'cmessage'
] as const
type FieldsType = typeof Fields[number]
type State = Record<FieldsType, string>;

export const ContactModal = (p: Props) => {
    const {children} = p;
    const {t} : TranslationTypes = useTranslation();

    const field2Body = (s: Partial<State>) =>
        Fields
            .filter(f => !['cmessage'].includes(f))
            .reduce((prev, current) => (prev+' - '+t(current as any)+ ': '+encodeURI((s?.[current] || '')+'\n')), '')


    const [state, setState] = useState<Partial<State>>({});
    const onChange = (name: FieldsType) => (e: any) => setState(s => ({
        ...s,
        [name]: e.target.value
    }))
    const onSend = () => {
        const url = `mailto:${encodeURI(t('contact_email'))}
            ?subject=${encodeURI(t('ctitle') + ' - ' + (state.cfirstname)+' '+(state.clastname)+ ' - ' + new Date().toLocaleDateString())} 
            &body=${field2Body(state)}${encodeURI('\n'+'\n'+(state?.cmessage || ''))}`;
        window.open(url, '_blank');
        p.onHide();
    }

    return (
        <div
            className={`fixed ${p.show ? 'block' : 'hidden'} top-0 left-0 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full`}
            id="my-modal"
        >
            <div
                className="relative top-20 mx-auto p-5 border lg:w-[36rem] w-96 shadow-lg rounded-md bg-white"
            >
                <form className="w-full max-w-lg mx-auto">
                    <span className="text-3xl text-black font-bold">{t('ctitle')}</span>
                    <div className="flex flex-wrap -mx-3 mb-6 mt-6">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-first-name">
                                {t('cstart')}
                            </label>
                            <input
                                value={state?.cstart}
                                onChange={onChange('cstart')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id="grid-first-name" type="date" placeholder="01/01/2023"/>
                            {/* border-red-500 */}
                            {/*<p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-first-name">
                                {t('cnights')}
                            </label>
                            <input
                                value={state?.cnights}
                                onChange={onChange('cnights')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id="grid-first-name" type="number" placeholder="3"/>
                            {/*<p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-last-name">
                                {t('cpersons')}
                            </label>
                            <input
                                value={state?.cpersons}
                                onChange={onChange('cpersons')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-first-name" type="number" placeholder="3"/>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-first-name">
                                {t('cfirstname')}
                            </label>
                            <input
                                value={state?.cfirstname}
                                onChange={onChange('cfirstname')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id="grid-first-name" type="text" placeholder="Jane"/>
                            {/*<p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-last-name">
                                {t('clastname')}
                            </label>
                            <input
                                value={state?.clastname}
                                onChange={onChange('clastname')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-last-name" type="text" placeholder="Dupond"/>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-first-name">
                                {t('cemail')}
                            </label>
                            <input
                                value={state?.cemail}
                                onChange={onChange('cemail')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id="grid-first-name" type="email" placeholder=""/>
                            {/*<p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-last-name">
                                {t('ctel')}
                            </label>
                            <input
                                value={state?.ctel}
                                onChange={onChange('ctel')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-last-name" type="tel" placeholder=""/>
                        </div>
                    </div>
                    {/*<div className="flex flex-wrap -mx-3 mb-6">*/}
                    {/*    <div className="w-full px-3">*/}
                    {/*        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                    {/*               htmlFor="grid-password">*/}
                    {/*            {t('cdays')}*/}
                    {/*        </label>*/}
                    {/*        <input*/}
                    {/*            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
                    {/*            id="email" type="email"/>*/}
                    {/*        /!*<p className="text-gray-600 text-xs italic">Some tips - as long as needed</p>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-password">
                                {t('caddress')}
                            </label>
                            <input
                                value={state?.caddress}
                                onChange={onChange('caddress')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="email" type="email"/>
                            {/*<p className="text-gray-600 text-xs italic">Some tips - as long as needed</p>*/}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-first-name">
                                {t('ccountry')}
                            </label>
                            <input
                                value={state?.ccountry}
                                onChange={onChange('ccountry')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id="grid-first-name" type="text" placeholder=""/>
                            {/*<p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-first-name">
                                {t('ccity')}
                            </label>
                            <input
                                value={state?.ccity}
                                onChange={onChange('ccity')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id="grid-first-name" type="text" placeholder=""/>
                            {/*<p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-last-name">
                                {t('cpcode')}
                            </label>
                            <input
                                value={state?.cpcode}
                                onChange={onChange('cpcode')}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-last-name" type="text" placeholder=""/>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-password">
                                {t('cmessage')}
                            </label>
                            <textarea
                                value={state?.cmessage}
                                onChange={onChange('cmessage')}
                                className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                                id="message"></textarea>
                            {/*<p className="text-gray-600 text-xs italic">Re-size can be disabled by set by resize-none /*/}
                            {/*    resize-y / resize-x / resize</p>*/}
                        </div>
                    </div>
                    <div className="md:flex md:items-center">
                        <div className="md:w-2/3">
                            <button
                                onClick={onSend}
                                className="shadow bg-red-500 hover:bg-red-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                                type="button">
                                {t('csend')}
                            </button>
                            <button
                                onClick={p.onHide}
                                className="ml-5 shadow bg-gray-500 hover:bg-gray-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                                type="button">
                                {t('ccancel')}
                            </button>
                        </div>
                        <div className="md:w-1/3"></div>
                    </div>
                </form>
            </div>
        </div>
    );
}
