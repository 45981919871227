import {TKeys} from "./index";

// @ts-ignore
export const contentNl : Record<TKeys, string> = {
    "short_title": "Le Chéniat",
    "title": "Bed and breakfast - Le Chéniat",
    "short_desc": "Guest room in Walloon Brabant spacious and warm",
    "_figcaption": "Official B&B classification in Wallonia: 3 stars",
    "figcaption": "Recognized by the General Commission for Tourism: Bed and Breakfast classified 3 Epis",
    figcaption_bike: 'Label Fiets Welkom',
    "section_openings": "Ontvangst", // Openingstijden
    "hours_1": "Wij staan het hele jaar te uwer beschikking",
    "hours_2": "Aankomst tussen 17:00 uur en uiterlijk 21.00 uur",
    "hours_3": "Vertrek uiterlijk 11:00 uur",
    "section_rooms": "Gastenverblijf",
    "section_rooms_short": "Slaapkamers",
    "subsection_A": "Hoofdslaapkamer",
    "subsection_A_desc": "Een ruime slaapkamer van 30 m² met een tweepersoonsbed en een optioneel eenpersoonsbed.",
    "subsection_B": "Tweede slaapkamer",
    "subsection_B_desc": "De tweede slaapkamer van 9 m² met een eenpersoonsbed en een kinderbedje en een commode grenst aan de hoofdslaapkamer",
    "section_options": "Opties",
    section_options_short: "Opties",
    "subsection_C": "Prive badkamer",
    "subsection_C_desc1": "Beide gastenkamers hebben de beschikking over een doucheruimte, toilet,",
    "subsection_C_desc2": "haardroger en badjassen",
    "subsection_D": "Prijzen (inclusief een gastronomisch ontbijt)",
    "subsection_D_desc0a": "•  85 € /nacht voor het tweepersoonsbed",
    "subsection_D_desc0b": "• + 15 € /nacht voor elk extra eenpersoonsbed",
    "subsection_D_desc1": "-10% vanaf 2 nachtens",
    "subsection_D_desc2": "De prijsopgave voor een lang verblijf : beschikbaar op aanvraag",
    "option1": "Gastronomisch ontbijt is bij de prijs van de kamer inbegrepen.",
    "option1_desc": "Het wordt geserveerd in de woonkamer of op het overdekte terras: diverse broodsoorten, bio appelsap, verse sinaasappelsap, vers fruit, yoghurt en eieren op verschillende manieren, kaas en vleeswaren, cake of pannenkoeken en zelfgemaakte confituur, gebak, ontbijtgranen, koffie , thee, melk.",
    "old_option1": "Simple sweet and savory breakfast (+ 5€/pp) or standard (+10€/pp)",
    "old_option1_desc": "Breakfasts are served inside or on the covered terrace: apple juice from the orchard, pressed orange juice, yogurt and eggs in different ways, cheese and charcuterie, home-made pancakes and jams, pastries, coffee, tea.",
    "option2": "Op aanvraag verzorgen wij een cateringservice voor lunch en/of avond: ",
    option2_url: 'https://www.facebook.com/une.autre.epoque.baisy.thy',
    option2_title: 'Facebook page',
    "option3": "Kinderbedje (gratis)",
    "option4": "Extra eenpersoonsbed (+15 €/bed)",
    "section_localisation": "Locatie & toegang",
    "car": "Gemakkelijke toegang tot autosnelwegen naar Brussel - Charleroi - Namen - Luik.",
    "flights": "Charleroi Airport (20 km) - Brussels Airport (35 km) - Optioneel bestaat de mogelijkheid tot shuttle (onder aanvraag)",
    "trains": "Trein: stations in de buurt zijn Ottignies (15 km) en Braine-l'Alleud (15 km)",
    "address": "25A Rue Chéniat, 1470 Genappe (Baisy-Thy), België",
    "section_contact": "Contact",
    "contact_cellphone": "+32 475 55 04 51",
    "contact_phone": "+32 67 79 03 82",
    "contact_fullname": "Le Chéniat",
    "contact_email": "lecheniat@gmail.com",
    "facebook_url": "https://www.facebook.com/Chambre-dh%C3%B4tes-Le-Ch%C3%A9niat-1523712734377878",
    "section_description": "Gastenverblijf",
    "medium_desc_1": "In a calm and rural environment, this charming house occupies a central position at the crossroads of several tourist sites which allows you to discover Walloon Brabant.",
    "medium_desc_2": "Le Chéniat offers a double room capable of accommodating up to 4 people.",
    "medium_desc_3": "Languages spoken: English, French, Dutch",
    "ranking": "3 stars(s)",
    "section_capacity": "Capacity",
    "n_room": "1*",
    "n_simple_bed": "2",
    "n_baby_bed": "1",
    "n_double_bed": "1",
    "n_bathroom": "1",
    "n_wc": "1",
    "base_capacity": "4",
    "section_equipments": "Faciliteiten en diensten :",
    "item1": "- Internettoegang (WIFI)",
    "item2": "- Lakens en handdoeken inbegrepen",
    "item3": "- Grote tuin",
    "item4": "- Tuinmeubilair",
    "item5": "- Parking - incl. 11 kW laadstation",
    "item6": "- Buitenzwembad (enkel in het juiste seizoen): ligstoelen en badhanddoeken",
    "item7": "- Afgesloten terrein",
    "item9": "- Terras",
    "misc11": "- Bed and breakfast",
    "misc12": "- English French Dutch",
    "misc13": "FTPBW Publishing",
    "misc14": "Memory tourism",
    "book": "Reserveren",
    "section_activities": "Ontdek Waals-Brabant",
    section_activities_short: 'Ontdekkingen',
    "a1_city": "Louvain-La-Neuve (15 km)",
    "a1_list": "Wetenschapspark, Hergé Museum, L Museum, Bois de Lauzelle, Bois des Rêves,\n",
    "a2_city": "Waver (15 km)",
    "a2_list": "Walibi,",
    "a3_city": "Villers-La-Ville (3 km)",
    "a3_list": "Abdij, microbrouwerij,\n",
    a3_url0: 'https://villers.be/nl',
    a3_url0_text: 'Officiële website',
    "a4_city": "Terhulpen (15 km)",
    "a4_list": "Stichting Folon: museum, Domaine Solvay, Château de la Hupe\n",
    "a5_city": "Golfbanen",
    "a5_list": "Rigenée, Bruyère, the Emperor, Hotel de Pierpont,...",
    "a6_city": "Wandel- & fietstochten",
    "a6_list": "Wij kunnen u informeren over de fietsnetwerken en de wandel- en mountainbikeroutes in de omgeving.\n",
    a7_city: 'Waterloo (12km)',
    "a6_url0": "https://eurovelobelgium.be/eurovelo/5",
    "a6_url0_text": "The EuroVelo route is 12 km away",
    "a6_url1": "https://www.visorando.com/fr-be/randonnee-villers-la-ville-belgique.html",
    "a6_url1_text": 'Routes in Villers-la-Ville - Visorando',
    "a6_url2": "https://www.villers-la-ville.be/vie-locale/tourisme/balades/ilovepdf-merged.pdf",
    "a6_url2_text": '(PDF) Walks in Villers-La-Ville',
    a7_url0: 'https://visitwallonia.be/fr-be/content/randonnee-pedestre-incontournable-au-coeur-de-la-bataille',
    a7_url0_text: 'Onmisbare wandeling op het Slagveld van Waterloo',
    a7_url1: 'https://visitwallonia.be/fr-be/produit/attractions/activites/musees/decouvertes/memorial-1815-champ-de-bataille-de-waterloo/9245',
    a7_url1_text: 'Memorial Waterloo 1815',
    a7_url2: 'https://www.museewellington.be/?lang=nl',
    a7_url2_old: 'https://visitwallonia.be/fr-be/content/musee-wellington-waterloo-retour-sur-la-bataille-de-1815',
    a7_url2_text: 'Wellington-museum',
    a7_url3: 'https://visitwallonia.be/fr-be/content/panorama-de-la-bataille-de-waterloo-une-fresque-a-360',
    a7_url3_text: 'Fresco van het panorama van de Slag bij Waterloo',
    a7_url4: 'http://www.waterloo-tourisme.com/nl',
    a7_url4_text: 'Toeristische dienst van Waterloo',
    "desc_title_1": "B&B - Bed and Breakfast in Baisy-Thy",
    "desc_title_2": "Charmant gastenverblijf in weelderige natuur",
    "old_desc_full_1": "In a rural setting near the abbey of Villers-la-Ville, this charming house occupies a central position at the crossroads of several tourist sites in Walloon Brabant.\nLe Chéniat offers a two-bedroom suite capable of accommodating up to 4 people.\nFor your professional stays, a romantic break or your sporting adventures, our accommodation offers you peace and comfort.\nAnd during a family stopover, the cot is offered to you.",
    "desc_full_1": "Bent u op zoek naar accommodatie in België, Wallonië, Waals-Brabant?\n" +
        "\"Le Chéniat\", in de buurt van de abdij van Villers-la-Ville, biedt een ruimte met twee slaapkamers van maximaal 4 personen.\n" +
        "Voor uw zakelijk verblijf, een romantisch uitje of uw sportieve avonturen, onze accommodatie zorgt voor rust en comfort.\n" +
        "En tijdens gezinsvakanties wordt het kinderbedje u aangeboden.",
    "desc_full_2": "Gesproken talen: Engels, Frans, Nederlands",
    "ext1": "De locatie beschikt over een aangename omheinde tuin, een beveiligd buitenzwembad en een overdekt terras en dit alles draagt bij uw welzijn en comfort.",
    "ext2": "Er zijn parkingplaatsen voorzien voor 2 tot 3 auto's en er is een laadpaal van 11 kW.",
    "ext3": "Uw fietsen kunnen veilig en overdekt gestald worden.",
    "living0": "Onze gasten kunnen gebruik maken van:",
    "living1": "Een ruime en lichte woonkamer van 32 m²",
    "living2": "Bibliotheek en ontspanningsruimte",
    "sdb1": "equipped with a shower room and a private WC.",
    "sdb2": "Hairdryer and bathrobes for your comfort.",
    "desk": "Wifi, bureau en PC scherm beschikbaar voor professioneel werk",
    "ctitle": "Reserveringsaanvraag",
    "clastname": "NAAM",
    "cfirstname": "VOORNAAM",
    "caddress": "ADRES",
    "ccity": "STAD",
    "cpcode": "POSTCODE",
    "ccountry": "LAND",
    "ctel": "TELEFOON",
    "cemail": "E-MAILEN",
    "cstart": "AANKOMSTDATUM",
    "cnights": "AANTAL NACHTEN",
    "cpersons": "AANTAL PERSONEN",
    "cobject": "Voorwerp",
    "cmessage": "BERICHT",
    "csend": "Verzenden",
    "ccancel": "Annuleren",
}


